import { useEffect } from 'react';
import NavigationBarLight from '../../NavigationBarLight';
import Footer from '../../Footer';

import blogImg from '../../../assets/images/blog/blog-img-1.png';


function BlogEntry1() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='blog-entry be-1'>
      <NavigationBarLight />
      <div className='container'>
        <div className='be-content'>
          <img className='be-1-img be-img' src={blogImg} />
          <h2 className='be-1-title be-title'>5 Hal Yang Pantang Dilakukan Wanita 7 Hari Sebelum Pernikahan</h2>
          <p className='be-1-desc be-desc'>by Fitri Liza | Kamis, 25 April 2024</p>
          <p className='be-text'>Berbagai pantangan yang harus diperhatikan calon pengantin wanita tujuh hari sebelum menjelang hari pernikahan. Memulai babak baru dalam kehidupan bukanlah hal yang mudah. Pastinya, ada banyak tantangan yang selalu datang menjelang pernikahan. Mulai dari masalah keluarga, kebutuhan pernikahan yang tak kunjung usai hingga mantan yang tiba-tiba muncul.Hal ini juga tidak menutup kemungkinan untuk para calon pasangan untuk mengurungkan niatnya untuk menikah beberapa hari menjelang hari-H. Oleh karena itu, itu mencegah hal-hal yang tidak diinginkan untuk terjadi, kamu perlu tahu apa saja hal yang sebaiknya tidak boleh dilakukan calon pengantin wanita sebelum pernikahan. Berikut hal-hal yang sebaiknya tidak dilakukan calon mempelai wanita tujuh hari sebelum pernikahan.</p><br/>

          <h3 className='be-subheader'>1. Mencoba produk skincare atau makeup baru</h3>

          <p className='be-text'>
          Jika wajahmu ingin terlihat berkilau dan mulus di hari spesial, maka sebaiknya kamu jangan mencoba skincare yang belum pernah coba sama sekali. 
          
          Hal ini dikarenakan bisa jadi perawatan kulit tersebut tidak cocok dengan wajahmu dan menimbulkan reaksi alergi yang tidak diinginkan seperti kemerahan, gatal-gatal dan tumbuh jerawat. <br/><br/>
          
          Untuk reaksi seperti ini, kemungkinan besar butuh waktu yang cukup lama untuk pulih ke kondisi semula. Bedasarkan jurnal National Library of Medicine, kulit pada umumnya membutuhkan waktu minimal sekitar 40 sampai dengan 60 hari untuk proses regenerasi kembali ke kondisi semula setelah mengalami breakout atau alergi.
          
          Tentunya, jika pengantin mengalami breakout pada kulit, hal ini juga mempersulit pekerjaan makeup artist karena jika kondisi kulit tidak optimal, maka hasil make up juga akan tidak maksimal, sehingga cenderung tidak awet dan mudah hancur. <br/><br/>
          
          Maka dari itu, untuk mencegah berbagai reaksi alergi yang tidak diinginkan, calon pengantin disarankan untuk tidak mencoba produk kecantikan baru menjelang hari pernikahan. Hal ini dilakukan agar calon pengantin bisa menjaga kondisi kulit yang prima. <br/><br/>
          
          Jika Anda memang berniat mencoba produk perawatan kulit baru, cobalah produk tersebut setidaknya sebulan sebelum pernikahan. Ini akan memberi Anda cukup waktu untuk mengenali bagaimana kulit Anda meresponsnya. <br/>
          
          Sama halnya dengan skincare, mencoba produk makeup baru satu minggu sebelum menikah bukanlah hal tepat yang dilakukan. Hal ini harus dilakukan untuk mengantisipasi berbagai macam reaksi alergi pada kulit yang tidak diinginkan. Jika kulit kamu mengalami alergi atau berjerawat, kamu tidak punya waktu untuk memperbaiki kondisi kulitmu seperti kondisi semula dalam kurun waktu tujuh hari. Maka dari itu, sangat disarankan untuk para calon pengantin wanita agar tetap menggunakan produk yang telah Anda gunakan selama ini. <br/><br/>
          
          Sekalipun itu adalah item makeup baru, pastikan untuk mengujinya jauh sebelum pernikahan, minimal satu bulan sebelumnya. Gunakan hanya kosmetik yang sudah sesuai dengan kulit Anda menjelang hari pernikahan. <br/><br/>
          </p>

          <h3 className='be-subheader'>2. Mewarnai dan memotong rambut</h3>
          <p className='be-text'>
          Selain mencoba makeup, hal lainnya yang harus dihindari ialah mewarnai rambut. Sama halnya dengan produk skincare atau makeup, cat rambut juga bisa menimbulkan reaksi alergi pada kulit kepala dan membuat penataan rambut menjadi sulit. Selain cat rambut, calon pengantin juga tidak disarankan untuk memotong rambut dengan gaya ekstrim, karena hal ini akan mempersulit penata rambut untuk melakukan hair-do atau styling rambut di hari pernikahan. <br/><br/>
          Untuk menjaga kesehatan rambut menjelang pernikahan, Anda bisa melakukan hair mask atau creambath sederhana saja di rumah atau salon untuk rambut yang lebih sehat dan berkilau pada hari pernikahan. <br/><br/>
          </p>
          <h3 className='be-subheader'>3. Sering begadang</h3>
          <p className='be-text'>
          Tubuh Anda membutuhkan istirahat yang cukup setiap malam untuk merasa segar kembali. Kecuali Anda jam tidur 8 jam padat, Anda tidak akan memiliki energi untuk menangani tantangan minggu ini. Terlepas dari seberapa banyak tugas yang harus dilakukan di minggu ini, pastikan Anda mendapatkan tidur yang dibutuhkan.<br/><br/>
          
          Tanpa tidur yang cukup, perawatan kecantikan dan kulit Anda tidak akan memberikan hasil yang optimal. Anda juga akan berakhir dengan mata bengkak dan lingkaran hitam. Kulit Anda juga akan terlihat lesu dan kusam. Kurang tidur juga dapat memengaruhi kekebalan dan kesejahteraan Anda secara keseluruhan. Berikan tubuh dan pikiran Anda istirahat yang layak, sehingga Anda dapat berada dalam kondisi terbaik di hari istimewa Anda.<br/><br/>

          </p>
          <h3 className='be-subheader'>4. Bertengkar dengan calon suami</h3>
          <p className='be-text'>
          Salah satu cobaan yang sering terjadi menjelang hari pernikahan ialah perbedaan pendapat antara kedua calon mempelai. Hal ini seringkali memunculkan perdebatan yang berujung dengan pertengkaran. Ini tentunya bukanlah kondisi ideal.<br/><br/>
          
          Untuk mengatasi masalah ini, cobalah untuk mengelola intensitas komunikasi dengan calon pasangan beberapa hari menjelang pernikahan. Tidak terlalu sering namun juga bukan berarti tidak berkomunikasi sama sekali. Komunikasikan hal yang dianggap penting, misalnya terkait urusan detail pernikahan.<br/><br/>
          
          Dari kacamata adat, prosesi midodareni dalam adat Jawa, yang mana mengharuskan calon suami dan istri tidak bertemu dan berkomunikasi guna mencegah hal-hal yang tak diinginkan seperti timbulnya beda pendapat atau pertengkaran sebelum pernikahan.<br/><br/>
          </p>

          <h3 className='be-subheader'>5. Meragukan diri sendiri</h3>
          <p className='be-text'>
          Menjelang pernikahan, seringkali perasaan gugup akan mengundang berbagai perasaan negatif lainnya seperti rasa sedih, cemas, atau minder. Hal ini dikarenakan kamu merasa tidak pantas untuk pasangan.<br/><br/>
          
          Untuk mengatasi hal ini, pastikan dirimu jangan sampai tenggelam dalam perasaan negatif. Isilah hari-harimu sebelum menikah dengan kegiatan positif yang membuat hati bahagia.<br/><br/>
          
          Nah, itu dia beberapa pantangan yang wajib diketahui dan dihindari para calon pengantin wanita satu minggu menjelang hari pernikahan. Jika berencana menikah dalam waktu dekat, pastikan kamu untuk tidak melakukan hal-hal diatas ya.<br/><br/>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default BlogEntry1;
