import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';

import Loader from '../components/Loader';

function Pricelist() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  
  return (
    <div className='pricelist'>
      <NavigationBar />
      <div className='pl-banner'>
        <div className='container'>
          <div className='pl-header col-12 col-md-6 col-lg-6' data-aos="100"></div>
        </div>
      </div>
      <div className='pl-content'>
        <div className='container'>
          <div className='pl-elmt-container row justify-content-center'>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='pl-elmt'>
                <div className='pl-elmt-header pl-elmt-header-silver'>
                  <p className='pl-elmt-paket'>Paket Akad / Resepsi</p>
                  <h2 className='pl-elmt-name'>SILVER</h2>
                </div>
                <p className='pl-price'>Rp29.500.000</p>
                <div className='pl-body'>
                  <ul className='pl-list'>
                    <li className='pl-list-elmt'>Tata busana pengantin untuk resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata rias pengantin untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Makeup hairdo/hijabdo ibu mempelai</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Aksesoris pengantin pria dan wanita</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua pria untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua wanita untuk akad/resepsi (tanpa kebaya dan selop)</li>
                  </ul>
                </div>
              </div>
              <div className='pl-btn-container'>
              <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20Paket%20Silver%20di%20House%20of%20Liza." target="__blank">
                  <div className='pl-btn'>KONSULTASI</div>
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='pl-elmt'>
                <div className='pl-elmt-header pl-elmt-header-gold'>
                  <p className='pl-elmt-paket'>Paket Akad / Resepsi</p>
                  <h2 className='pl-elmt-name'>GOLD</h2>
                </div>
                <p className='pl-price'>Rp33.000.000</p>
                <div className='pl-body'>
                  <ul className='pl-list'>
                    <li className='pl-list-elmt'>Tata busana pengantin untuk resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata rias pengantin untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Makeup hairdo/hijabdo ibu mempelai</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Finishing rias pengantin untuk resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Aksesoris pengantin pria dan wanita</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua pria untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua wanita untuk akad/resepsi (tanpa kebaya dan selop)</li>
                  </ul>
                </div>
              </div>
              <div className='pl-btn-container'>
                <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20Paket%20Gold%20di%20House%20of%20Liza." target="__blank">
                  <div className='pl-btn'>KONSULTASI</div>
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='pl-elmt'>
                <div className='pl-elmt-header pl-elmt-header-platinum'>
                  <p className='pl-elmt-paket'>Paket Akad / Resepsi</p>
                  <h2 className='pl-elmt-name'>PLANTINUM</h2>
                </div>
                <p className='pl-price'>Rp36.500.000</p>
                <div className='pl-body'>
                  <ul className='pl-list'>
                    <li className='pl-list-elmt'>Tata busana pengantin untuk resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata rias pengantin untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Makeup hairdo/hijabdo ibu mempelai</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Finishing rias pengantin untuk resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Aksesoris pengantin pria dan wanita</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua pria untuk akad/resepsi</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata busana orang tua wanita untuk akad/resepsi (tanpa kebaya dan selop)</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata rias dan busana among tamu wanita 6 orang (tanpa kebaya dan selop)</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Tata rias dan busana among tamu pria 6 orang</li>
                  </ul>
                </div>
              </div>
              <div className='pl-btn-container'>
                <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20Paket%20Platinum%20di%20House%20of%20Liza." target="__blank">
                  <div className='pl-btn'>KONSULTASI</div>
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='pl-elmt'>
                <div className='pl-elmt-header pl-elmt-header-silver'>
                  <p className='pl-elmt-paket'>Wedding Package</p>
                  <h2 className='pl-elmt-name'>JAWA</h2>
                </div>
                <p className='pl-price'>Rp70.000.0000</p>
                <div className='pl-body'>
                  <ul className='pl-list'>
                    <li className='pl-list-elmt'>Tata busana dan rias pengantin</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Dokumentasi / Siraman / Musik / WO by Liza Organizer</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Upacara Adat</li>
                  </ul>
                </div>
                <div className='pl-btn-container'>
                <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20Jawa%20Wedding%20package%20di%20House%20of%20Liza." target="__blank">
                    <div className='pl-btn'>KONSULTASI</div>
                  </Link>
                </div>
              </div>
            </div>
            <div className='col-12 col-md-6 col-lg-4'>
              <div className='pl-elmt'>
                <div className='pl-elmt-header pl-elmt-header-gold'>
                  <p className='pl-elmt-paket'>Wedding Package</p>
                  <h2 className='pl-elmt-name'>SUNDA</h2>
                </div>
                <p className='pl-price'>Rp70.000.000</p>
                <div className='pl-body'>
                  <ul className='pl-list'>
                  <li className='pl-list-elmt'>Tata busana dan rias pengantin</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Dokumentasi / Siraman / Musik / WO by Liza Organizer</li>
                    <hr className='pl-hr'/>
                    <li className='pl-list-elmt'>Upacara Adat</li>
                  </ul>
                </div>
                <div className='pl-btn-container'>
                  <Link to="https://wa.me/628118019985?text=Halo%20Mican,%20aku%20mau%20konsultasi%20tentang%20Sunda%20Wedding%20package%20di%20House%20of%20Liza." target="__blank">
                    <div className='pl-btn'>KONSULTASI</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Pricelist;
